import './static/fonts/fonts.css'
import './src/main.css'

function trackPageView({ location }) {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined

  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath })
    }
  }, 100)
}

export const onRouteUpdate = ({ location }) => {
  trackPageView({ location })

  return true
}
