module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Polysport","short_name":"Polysport","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/polysport-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2375b03695dbf40f3e802ecf0fcd8fe5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"de","siteUrl":"https://www.polysport.de/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":false},{"matchPath":"/:lang?/:any*","getLanguageFromPath":true,"languages":[]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.02,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 60%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
